import React, {useState} from 'react';
import './proudct-cart.css';
import {Product} from '../../http/product';
import {useCartStore} from '../../store/useCartStore';
import {formatCurrency} from '../../services/sundry';
import toast from 'react-hot-toast';

interface ProductCartProps {
    product: Product
    closeModal: () => void;
}

export const ProductCart: React.FC<ProductCartProps> = ({product, closeModal}) => {
    const addToCart = useCartStore(({ addToCart }) => addToCart);
    const getTotalQuantityById = useCartStore(({getTotalQuantityById}) => getTotalQuantityById);
    const [quantity ,setQuantity] = useState(getTotalQuantityById(product._id));
    const removeItem = useCartStore(({removeItem}) => removeItem);

    const addItem = (): void => {
        removeItem(product._id);
        addToCart({...product, quantity: Number(quantity)});
        closeModal();
        notifyAddToCard();
    };

    const onCountChanged = (event: any): void => {
        setQuantity(event.target.value);
    }

    const notifyAddToCard = () => toast.success('Товар в корзине.');

    return (
        <div className="sh-product-cart">
            <img className="sh-product-cart__img"  src={product?.image} alt={product?.description}/>
            <div className="sh-product-cart__content">
                <div className='sh-product-cart__content-info'>
                    <h2>{product?.description}</h2>
                    <p>{formatCurrency(product?.price)}</p>
                </div>
                <div className="number">
                    <button className="minus" onClick={() => setQuantity(Number(quantity) > 0 ? Number(quantity) - 1 : Number(quantity))}>-</button>
                    <input type="number" value={quantity} onChange={onCountChanged}/>
                    <button className="minus" onClick={() => setQuantity(Number(quantity) + 1)}>+</button>
                </div>
                <button disabled={!quantity} className="sh-product-cart__content-btn" onClick={addItem}>Добавить в корзину</button>
            </div>
        </div>
    )
};