import {Product} from '../../http/product';
import './orderDetails.css';
import {formatCurrency} from '../../services/sundry';
import {useCartStore} from '../../store/useCartStore';
import React, {useState} from 'react';
import {ArrowDown, ArrowUp} from '../Icons/Icons';

interface OrderDetailsProps {
    products: Product[];
}

export const OrderDetails: React.FC<OrderDetailsProps> = ({products}) => {
    const getTotalPrice = useCartStore(({getTotalPrice}) => getTotalPrice);
    const getTotalPriceWithDelivery = useCartStore(({getTotalPriceWithDelivery}) => getTotalPriceWithDelivery);
    const [isOrderDetailsVisible, setIsOrderDetailsVisible] = useState(window.innerWidth > 768);

    return (
        <div className="sh-order-details">
            <div className="sh-order-detailstitle">
                Детали заказа ({products?.length})
                <div className="sh-order-detailstitle-show-hide">
                    {
                        !isOrderDetailsVisible ?
                            <div className="sh-order-detailstitle-show-hide-btn" onClick={() => setIsOrderDetailsVisible(true)}>
                                {formatCurrency(getTotalPriceWithDelivery())} <ArrowDown size={'sm'}/>
                            </div>
                            : <div className="sh-order-detailstitle-show-hide-btn" onClick={() =>  setIsOrderDetailsVisible(false)}>
                                {formatCurrency(getTotalPriceWithDelivery())} <ArrowUp size={'sm'}/>
                            </div>
                    }
                </div>
            </div>

            {
                isOrderDetailsVisible && <>
                    <div className="sh-order-detailsitems">
                        {products?.map((item => (
                            <div className="sh-order-detailsitems-section" key={item?._id}>
                                <div className="sh-order-detailsitems-section--item">
                                    <img src={item?.image} alt={item?.description}/>
                                </div>
                                <div className="sh-order-detailsitems-section--item">
                                    <div className="sh-order-detailsitems-section--item-sub">
                                        {item?.description}
                                    </div>
                                    <div className="sh-order-detailsitems-section--item-quantity">
                                        К-во: {item?.quantity}
                                    </div>
                                </div>
                                <div className="sh-order-detailsitems-section--item">
                                    {formatCurrency(item.price * item.quantity)}
                                </div>
                            </div>
                        )))}
                    </div>
                    <div className="sh-order-detailstotal">
                        <div className="sh-order-detailstotal-items">
                            <span className="sh-order-detailstotal-items-left">Сумма</span>
                            <span className="sh-order-detailstotal-items-right">{formatCurrency(getTotalPrice())}</span>
                        </div>
                        <div className="sh-order-detailstotal-items">
                            <span className="sh-order-detailstotal-items-left">Доставка</span>
                            <span className="sh-order-detailstotal-items-right">{formatCurrency(1000)}</span>
                        </div>
                        <div className="sh-order-detailstotal-items">
                            <span className="sh-order-detailstotal-items-left">Итого</span>
                            <span
                                className="sh-order-detailstotal-items-right">{formatCurrency(getTotalPriceWithDelivery())}</span>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}