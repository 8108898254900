import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram} from '@fortawesome/free-brands-svg-icons';
import {faTimes, faArrowUp, faArrowDown, faShoppingCart, faBars, faSpinner} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

interface DefaultProps {
    size?: string;
    color?: string;
}

interface InstagramIconProps extends DefaultProps{}

interface CartIconProps extends DefaultProps {
    itemCount: number;
}

interface CloseIconProps extends DefaultProps {}

export const InstagramIcon: React.FC<InstagramIconProps> = ({size, color}) => {
    return (
        <FontAwesomeIcon icon={faInstagram} style={{fontSize: size, color}}/>
    );
}

export const CartIcon: React.FC<CartIconProps> = ({size, color, itemCount}) => {
    return (
        <div style={{position: 'relative', display: 'inline-block'}}>
            <FontAwesomeIcon
                icon={faShoppingCart as IconProp}
                style={{
                    fontSize: size,
                    color: color,
                    cursor: 'pointer'
                }}
            />
            {itemCount > 0 && ( // Render count only if there are items in the cart
                <div
                    style={{
                        position: 'absolute',
                        top: '-8px',
                        right: '-8px',
                        backgroundColor: '#ff0000',
                        color: '#fff',
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {itemCount}
                </div>
            )}
        </div>
    );
}

export const BurgerIcon = () => {
    return (<FontAwesomeIcon icon={faBars} size="2x" color="black"/>)
}

export const CloseIcon: React.FC<CloseIconProps> = ({size}) => {
    // @ts-ignore
    return (<FontAwesomeIcon icon={faTimes} size={size} color="black"/>)
}

export const Loading = () => {
    return (<FontAwesomeIcon icon={faSpinner} size="3x" spin className="sh-spinner"/>);
};

export const ArrowUp: React.FC<DefaultProps> = ({size}) => {
    // @ts-ignore
    return (<FontAwesomeIcon icon={faArrowUp} size={size} />);
}

export const ArrowDown: React.FC<DefaultProps> = ({size}) => {
    // @ts-ignore
    return (<FontAwesomeIcon icon={faArrowDown} size={size} />);
}