import './footer.css';

export const Footer = () => {
    const getCurrentYear = (): string => {
        const madeYear = 2024;
        const currentYear = new Date().getFullYear();
        return currentYear > 2024 ? `${madeYear} - ${currentYear}` : `${madeYear}`;

    }
    return (
        <footer className="sh-footer">
            &copy;{getCurrentYear()} Shakirov-Honey
        </footer>
    )
};