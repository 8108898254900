import './createOrder.css';
import {useCartStore} from '../../store/useCartStore';
import {OrderDetails} from '../../shared/orderDetails/OrderDetails';
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {ClientData} from '../../shared/clientData/clientData';

export const CreateOrder = () => {
    const cart = useCartStore(({cart}) => cart);
    const navigate = useNavigate();

    useEffect(() => {
        if (!cart?.length) {
            navigate('/cart');
        }
    }, []);
    return (
        <div className="sh-create-order">
            <ClientData/>
            <div className="sh-create-orderdetails">
                <OrderDetails products={cart}/>
            </div>
        </div>
    )
}