import './cart.css';
import {useCartStore} from '../../store/useCartStore';
import {Product} from '../../http/product';
import {formatCurrency} from '../../services/sundry';
import {CloseIcon} from '../../shared/Icons/Icons';
import React from 'react';
import {useNavigate} from 'react-router-dom';

export const Cart = () => {
    const cart = useCartStore(({cart}) => cart);
    const getTotalPrice = useCartStore(({getTotalPrice}) => getTotalPrice);
    const removeItem = useCartStore(({removeItem}) => removeItem);
    const incrementQuantity = useCartStore(({incrementQuantity}) => incrementQuantity);
    const decrementQuantity = useCartStore(({decrementQuantity}) => decrementQuantity);
    const navigate = useNavigate();

    return (
        <>
            {cart?.length > 0 ?
                <div className="sh-cart">
                    <div className="sh-cart-items">
                        <div className="sh-cart-title">
                            Моя корзина
                        </div>
                        {cart?.map((item: Product) => (
                            <div className="sh-cart-items__item" key={item?._id}>
                                <img className="sh-cart-items__item--img" src={item?.image} alt={item?.description}/>
                                <div className="sh-cart-items__item--description">
                                    <div className="sh-cart-items__item--description-title">
                                        {item?.description}
                                    </div>
                                    <div className="sh-cart-items__item--description-price">
                                        {formatCurrency(item?.price)}
                                    </div>
                                </div>

                                <div className="sh-cart-items__item-input-number">
                                    <button className="sh-cart-items__item-input-number--btn"
                                            onClick={() => decrementQuantity(item?._id)}>-
                                    </button>
                                    <input className="sh-cart-items__item-input-number--input" type="number"
                                           value={item?.quantity}/>
                                    <button className="sh-cart-items__item-input-number--btn"
                                            onClick={() => incrementQuantity(item?._id)}>+
                                    </button>
                                </div>

                                <div className="sh-cart-items__item--total-quantity">
                                    {formatCurrency(item.quantity * item.price)}
                                </div>

                                <div className="sh-cart-items__item--close-icon" onClick={() => removeItem(item?._id)}>
                                    <CloseIcon size={'1x'}/>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="sh-cart-details">
                        <div className="sh-cart-title">
                            Детали заказа
                        </div>

                        <div className="sh-cart-details-item-sum sh-cart-details-item-padding sh-cart-details-flex">
                            <span>Cумма</span> <span
                            className="sh-cart-details-flex-currency">{formatCurrency(getTotalPrice())}</span>
                        </div>
                        <div className="sh-cart-details-item-delivery sh-cart-details-flex">
                            <span>Доставка</span> <span
                            className="sh-cart-details-flex-currency">{formatCurrency(1000)}</span>
                        </div>

                        <div className="sh-cart-details-item-total sh-cart-details-flex">
                            <span>Итого</span> <span
                            className="sh-cart-details-flex-currency">{formatCurrency(getTotalPrice() + 1000)}</span>
                        </div>

                        <div className="sh-cart-details-create-order" onClick={() => navigate("/create-order")}>
                            Оформление заказа
                        </div>
                    </div>
                </div>
                :
                <div className="sh-cart-empty">
                    <div className="sh-cart-title">
                        Моя корзина
                    </div>
                    <div className="sh-cart-empty-title">
                        Корзина пуста
                    </div>
                </div>
            }
        </>
    )
}