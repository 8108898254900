import React from 'react';
import './App.css';
import {Header} from './shared/header/Header';
import {Footer} from './shared/footer/Footer';
import {Helmet} from 'react-helmet';
import logo from './assets/logo.png';
import {BrowserRouter as Router, Navigate, Route, Routes,} from "react-router-dom";
import {Honey} from './components/honey/Honey';
import {Loading} from './shared/Icons/Icons';
import {useSessionStore} from './store/useSessionStore';
import {Toaster} from 'react-hot-toast';
import {Cart} from './components/cart/Cart';
import {CreateOrder} from './components/create-order/CreateOrder';

export const App = () => {
    const loading = useSessionStore(({loading}) => loading);

    return (
        <>
            <div className="sh-container">
                <Helmet>
                    <title>Shakirov honey</title>
                    <meta name="description" content="Honet market"/>
                    <meta property="og:title" content="Shakirov honey"/>
                    <meta property="og:description" content="Honey market"/>
                    <meta property="og:image" content={logo}/>
                    <meta property="og:url" content="https://example.com"/>
                    <meta property="og:type" content="website"/>
                </Helmet>

                <Router>
                    <Header/>
                    <Routes>
                        <Route path="/" element={<Navigate to="/honey"/>}/>
                        <Route path="/honey" element={<Honey/>}/>
                        <Route path="/cart" element={<Cart/>}/>
                        <Route path="/create-order" element={<CreateOrder/>}/>
                    </Routes>
                    <Footer/>
                </Router>

                {
                    loading && (
                        <div className="sh-spinner-container">
                            <div className="sh-spinner-content">
                                <Loading/>
                            </div>
                        </div>
                    )
                }
            </div>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </>
    );
}

export default App;
