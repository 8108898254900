import './header.css';
import logo from '../../assets/logo.png';
import {BurgerIcon, CartIcon, CloseIcon, InstagramIcon} from '../Icons/Icons';
import {useState} from 'react';
import {useCartStore} from '../../store/useCartStore';
import {useNavigate} from 'react-router-dom';

export const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {getTotalQuantity} = useCartStore(({getTotalQuantity}) => ({getTotalQuantity}));
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const onNavigateToCartClicked = () => {
        navigate("/cart");
    }

    const onDropDownItemClicked = (param: string) => {
        setIsMenuOpen(false);
        navigate(`/honey?productType=${param}`);
    }

    return (
        <header>
            {/* Desktop Navbar */}
            <div className="sh-header">
                <div className="sh-header__left-side">
                    <img src={logo} className="sh-header__logo" alt="shakirov-honey-logo"
                         onClick={() => navigate('/')}/>
                    <ul className="sh-header__left-side-menu">
                        <li className="sh-header__left-side-menu__item">O нас</li>
                        <li className="sh-header__left-side-menu__item" onClick={() => onDropDownItemClicked('HONEY')}>Мёд</li>
                        <li className="sh-header__left-side-menu__item" onClick={() => onDropDownItemClicked('TEA')}>Чаи</li>
                        <li className="sh-header__left-side-menu__item" onClick={() => onDropDownItemClicked('HERBS')}>Травы</li>
                    </ul>
                </div>
                <div className="sh-header__right-side">
                    <ul className='sh-header__right-side-menu'>
                        <li className="sh-header__right-side-menu__item">shakirov-honey@gmail.com</li>
                        <li className="sh-header__right-side-menu__item">+7 776 845 67 02</li>
                        <li className="sh-header__right-side-menu__item">
                            <InstagramIcon size={'25'} color={'black'}/>
                        </li>
                    </ul>
                    <div onClick={onNavigateToCartClicked}>
                        <CartIcon size={'25'} color={'black'} itemCount={getTotalQuantity() || 0}/>
                    </div>
                </div>
            </div>
            {/* Desktop Navbar Ends*/}

            {/* Mobile Navbar*/}
            <div className="sh-header-mobile">
                <InstagramIcon size={'30'} color={'black'}/>
                <div onClick={onNavigateToCartClicked}>
                    <CartIcon size={'25'} color={'black'} itemCount={getTotalQuantity() || 0}/>
                </div>
                <div className="burger-icon" onClick={toggleMenu}>
                    <BurgerIcon/>
                </div>
                <div className={`sh-header-mobile__menu ${isMenuOpen ? 'open' : ''}`}>
                    <div className="sh-header-mobile__menu-top-menu">
                        <div className="close-button" onClick={toggleMenu}>
                            <CloseIcon size={'2x'}/>
                        </div>
                    </div>
                    <ul className="sh-header-mobile__menu-bottom-menu">
                        <li>shakirov-honey@gmail.com</li>
                        <li>+7 776 845 67 02</li>
                        <li>O нас</li>
                        <li onClick={() => onDropDownItemClicked('HONEY')} className="sh-header__right-side-menu__item">Мёд</li>
                        <li onClick={() => onDropDownItemClicked('TEA')} className="sh-header__right-side-menu__item">Чаи</li>
                        <li onClick={() => onDropDownItemClicked('HERBS')} className="sh-header__right-side-menu__item">Травы</li>
                    </ul>
                </div>
            </div>
            {/* Mobile Navbar Ends*/}
        </header>
    )
};