import {create} from 'zustand';
import {Product} from '../http/product';
import {persist, createJSONStorage} from 'zustand/middleware'

export const useCartStore = create<any>()(
    persist(
        (set: any, get: any) => ({
            cart: [],
            addToCart: (item: Product) => {
                const {cart} = get();
                const itemInCart = cart.find((i: Product) => i._id === item._id);
                const newCart = itemInCart
                    ? cart.map((i: Product) =>
                        i._id === item._id ? {...i} : i
                    )
                    : [...cart, {...item}];
                set({cart: newCart});
            },
            incrementQuantity: (_id: any) => {
                const newCart = get().cart.map((i: Product) =>
                    i._id === _id ? {...i, quantity: i.quantity + 1} : i
                );
                set({cart: newCart});
            },
            decrementQuantity: (_id: any) => {
                const newCart = get().cart.map((i: Product) =>
                    i._id === _id ? {...i, quantity: i.quantity - 1} : i
                );
                set({cart: newCart});
            },
            getTotalItems: () => get().cart.length,
            getTotalQuantity: () => get().cart.reduce((x: any, y: any) => x + y.quantity, 0),
            getTotalQuantityById: (_id: string) => {
                const product = get().cart.find((i: Product) => i._id === _id)
                if (product) {
                    return product.quantity;
                }
                return 0;
            },
            getTotalPrice: () => get().cart.reduce((x: any, y: any) => x + y.price * y.quantity, 0),
            removeItem: (_id: string) => {
                const newCart = get().cart.filter((i: Product) => i._id !== _id);
                set({cart: newCart});
            },
            getTotalPriceWithDelivery: () => get().getTotalPrice() + 1000
        }),
        {
            name: 'honey-storage',
            storage: createJSONStorage(() => localStorage),
        },
    )
)