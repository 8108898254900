import './productCard.css';
import React, {useState} from 'react';
import {Modal} from '../modal/Modal';
import {ProductCart} from '../productCart/productCart';
import {Product} from '../../http/product';
import {formatCurrency} from '../../services/sundry';

interface ProductCardProps {
   product: Product;
}

export const ProductCard: React.FC<ProductCardProps> = ({product}) => {
    const [isCartModalOpened, setCardModelState] = useState(false);
    const handleImgClick = () => {

    }

    const onAddToCardClicked = () => {
        setCardModelState(true);
    }
    return (
        <>
            <div className="sh-product-card">
                <div className="sh-product-card__content-item">
                    <div className="sh-product-card__content-item--image-container">
                        <img src={product?.image}
                             alt="" className="sh-product-card__content-item--image-container__product-image"/>
                        <button className="sh-product-card__content-item--image-container__view-product-button"
                                onClick={handleImgClick}>
                            Просмотр
                        </button>
                    </div>
                </div>
                <div className="sh-product-card__content-description">
                    {product?.description}
                </div>
                <div className="sh-product-card__content-price">
                    {formatCurrency(product?.price)}
                </div>
                <div className="sh-product-card__content-add-to-cart" onClick={onAddToCardClicked}>
                    в корзину
                </div>
            </div>

            <Modal
                bgClose={true}
                isOpen={isCartModalOpened}
                onClose={() => setCardModelState(false)}
            >
                <ProductCart
                    product={product}
                    closeModal={() => setCardModelState(false)}
                />
            </Modal>
        </>
)
};