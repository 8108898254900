import React from 'react';
import {ProductCard} from '../../shared/productCard/ProductCard';
import {ProductTypeEnum} from '../../enum/productType.enum';
import {useInfiniteScroll} from '../../hooks/useInfinityScroller';
import {useLocation} from 'react-router-dom';

export const Honey: React.FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const productType = searchParams.get('productType') as any;
    const {data} = useInfiniteScroll(productType ?? ProductTypeEnum.HONEY);

    return (
        <div className="sh-product-grid-container">
            {data.map((product, index) => (
                <ProductCard product={product} key={index}/>
            ))}
        </div>
    );
};
