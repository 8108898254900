import React, { useState } from 'react';
import './clientData.css';
import {createProductOrder} from '../../http/http';
import toast from 'react-hot-toast';
import {useCartStore} from '../../store/useCartStore';
import {useSessionStore} from '../../store/useSessionStore';

interface FormValues {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    country: string;
    address: string;
    city: string;
    postalCode: string;
}

function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const ClientData: React.FC = () => {
    const cart = useCartStore(({cart}) => cart);
    const getTotalPriceWithDelivery = useCartStore(({getTotalPriceWithDelivery}) => getTotalPriceWithDelivery);
    const showLoading = useSessionStore(({ showLoading }) => showLoading);
    const hideLoading = useSessionStore(({ hideLoading }) => hideLoading);
    const [formValues, setFormValues] = useState<FormValues>({
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        country: 'Казахстан',
        address: '',
        city: '',
        postalCode: ''
    });

    const [errors, setErrors] = useState<Partial<FormValues>>({});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const validationErrors: Partial<FormValues> = {};
        if (!formValues.email) {
            validationErrors.email = 'Эл. почта обязательна';
        }
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            showLoading();
            createProductOrder(
                {
                    ...formValues,
                    cart,
                    totalPrice: getTotalPriceWithDelivery(),
                    orderNumber: generateUUID(),
                })
                .then((response: any) => {
                    if (!response?.success) {
                        toast.error(response?.errors[0]);
                    } else {
                        toast.success('Ваш заказ оформлен. Проверьте свою почту или свяжитесь с нами в WhatsApp');
                    }
                    hideLoading();
                    setFormValues({
                        email: '',
                        firstName: '',
                        lastName: '',
                        phone: '',
                        country: 'Казахстан',
                        address: '',
                        city: '',
                        postalCode: ''
                    });
                })
                .catch(err => {
                toast.error(`${err.toString()}`);
            });
        }
    };

    return (
        <div className="sh-client-data">
            <div className="order-form-container">
                <h2>Оформление заказа</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">Эл. почта для подтверждения заказа*</label>
                        <input type="email" id="email" name="email" value={formValues.email} onChange={handleChange} required/>
                        {errors.email && <div className="error">{errors.email}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="firstName">Имя*</label>
                        <input type="text" id="firstName" name="firstName" value={formValues.firstName} onChange={handleChange} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastName">Фамилия*</label>
                        <input type="text" id="lastName" name="lastName" value={formValues.lastName} onChange={handleChange} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Телефон*</label>
                        <input type="tel" id="phone" name="phone" value={formValues.phone} onChange={handleChange} required/>
                    </div>
                    <div className="form-group">
                        <h3>Детали доставки</h3>
                        <div className="delivery-details">
                            <div className="form-group">
                                <label htmlFor="country">Страна/регион*</label>
                                <input disabled={true} type="text" id="country" name="country" value={formValues.country} onChange={handleChange} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="address">Адрес*</label>
                                <input type="text" id="address" name="address" value={formValues.address} onChange={handleChange} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="city">Город*</label>
                                <input type="text" id="city" name="city" value={formValues.city} onChange={handleChange} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="postalCode">Почтовый индекс*</label>
                                <input type="text" id="postalCode" name="postalCode" value={formValues.postalCode} onChange={handleChange} required/>
                            </div>
                        </div>
                    </div>
                    <button type="submit">Подтвердить заказ</button>
                </form>
            </div>
        </div>
    );
};
