import {create} from 'zustand';

export interface UseSessionStore {
    loading: boolean;
    showLoading: () => void;
    hideLoading: () => void;
}

export const useSessionStore = create<UseSessionStore>((set: any, get: any) => ({
    loading: false,
    showLoading: () => set({loading: true}),
    hideLoading: () => set({loading: false})
}));