import axios from 'axios'
import {Product} from './product';
import {Pageable} from '../interfaces/pageable';
import {HttpResponse} from '../interfaces/httpResponse';

// eslint-disable-next-line no-useless-concat
export const REST_BASE_URL = 'https://backend.shakirovhoney.kz';

const $api = axios.create({
    baseURL: REST_BASE_URL
});

export async function getProducts(type: string, page: number): Promise<Pageable<Product[]>> {
    try {
        const response = await $api.get<HttpResponse<Pageable<Product[]>>>(`${REST_BASE_URL}/product/get?category=${type}&page=${page}`);
        return response?.data?.data as any;
    } catch (error) {
        throw new Error('Failed to fetch products');
    }
}

export async function createProductOrder(data: any): Promise<any> {
    try {
        const response = await $api.post<HttpResponse<any>>(`${REST_BASE_URL}/product/create-product-order`, data);
        return response?.data as any;
    } catch (e) {
        throw new Error('Failed to create order');
    }
}
