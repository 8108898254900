import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Product } from '../http/product';
import { ProductTypeEnum } from '../enum/productType.enum';
import { useSessionStore } from '../store/useSessionStore';
import { getProducts } from '../http/http';

export const useInfiniteScroll = (productType: ProductTypeEnum) => {
    const [data, setData] = useState<Product[]>([]);
    const [page, setPage] = useState(0);
    const showLoading = useSessionStore(({ showLoading }) => showLoading);
    const hideLoading = useSessionStore(({ hideLoading }) => hideLoading);
    const loading = useSessionStore(({ loading }) => loading);
    const [hasMore, setHasMore] = useState(true);

    const fetchProducts = async (pageNumber: number) => {
        showLoading();
        try {
            const response = await getProducts(productType, pageNumber);
            const newData = response.result;
            setData(prevData => [...prevData, ...newData]);
            setHasMore(newData.length > 0);
            setPage(prevPage => prevPage + 1);
        } catch (error: any) {
            toast.error(error.message || 'Failed to fetch products');
        } finally {
            hideLoading();
        }
    };

    useEffect(() => {
        if (!hasMore || loading) return;

        const handleScroll = () => {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                fetchProducts(page);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, hasMore, page]);

    useEffect(() => {
        // Reset page and clear existing data when productType changes
        setPage(0);
        setData([]);
        fetchProducts(0); // Fetch products with page 0
    }, [productType]);

    return { data };
};
