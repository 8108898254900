import './modal.css';
import {CloseIcon} from '../Icons/Icons';
import React from 'react';
import {Transition, TransitionStatus} from 'react-transition-group';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: any;
    bgClose: boolean;
}

export const Modal: React.FC<ModalProps> = ({isOpen, onClose, children, bgClose}) => {
    const onWrapperClicked = (event: any) => {
        if (event.target.classList.contains('sh-modal__wrapper') && bgClose) {
            onClose();
        }
    }
    return (
        <>
            <Transition in={isOpen} timeout={350} unmountOnExit={true}>
                {(state: TransitionStatus) => (
                    <div className={`sh-modal sh-modal--${state}`}>
                        <div className="sh-modal__wrapper" onClick={onWrapperClicked}>
                            <div className="sh-modal__wrapper-content">
                                <button className="sh-modal__wrapper-content--close-btn" onClick={() => onClose()}>
                                    <CloseIcon size={'2x'}/>
                                </button>
                                {children}
                            </div>
                        </div>
                    </div>
                )}
            </Transition>
        </>
    )
}